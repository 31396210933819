.cm-col-100 {
  width: 100%;
  text-align: center;
}
.cm-col{
  padding: 0 1rem;
  display: inline-block;
}

.center{
  text-align: center;
}

.cm-row{
  padding: 1rem 0;
}

.section{
  padding-top: 2rem;
}
.pkg-field{
  width: 20rem;
}

.cm-btn{
  padding: 0 1rem;
  width: 8rem;
}