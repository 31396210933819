.package-card-wrapper .selector-overlay {
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    flex-direction: column-reverse;
}

.package-card-wrapper:hover .selector-overlay {
    display: flex;
}

.package-card-wrapper .selector-overlay * {
    opacity: 0.95;
}
